@import (reference) '../../style/global.less';

.header {
    height: 350px;
    min-width: 350px;
}

.leftCol {
    width: 350px;
}

.cell {
    min-width: 350px;
    height: 100%;

    p {
        margin-bottom: 0;
    }
}

.hint {
    p {
        margin-bottom: 0;
    }
}

.info {
    font-size: 18px;
}

.icon {
    min-width: 20px;
}

.point {
    font-weight: 600;
}

.price {
    width: 100%;
}

.border {
    border-left: solid 1px @alude-gray-6;
}

.table {
    width: fit-content;
}

.table > div:nth-child(even) {
    background-color: @alude-gray-1;
}
