@import (reference) '../../style/global.less';

.cell {
    min-width: 350px;
    height: 100%;

    p {
        margin-bottom: 0;
    }
}

.icon {
    min-width: 20px;
}

.border {
    border-left: solid 1px @alude-gray-6;
}

.hint {
    height: auto;
    & > p {
        white-space: break-spaces;
    }
}

.table {
    overflow-x: auto !important;
    width: inherit;
}

.table > div:nth-child(even) {
    background-color: @alude-gray-1;
}

.tableHeader {
    min-width: 700px;
}

.tableRow {
    min-width: fit-content;
}
